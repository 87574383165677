import { Card, CardBody } from "react-bootstrap";
import style from "@/pages/auth/index.module.scss";
export function AuthLayout({ children }: any) {
  return (
    <div className={"section-padding"}>
      <div className={"container"}>
        <div className={"col-lg-6 mx-auto"}>
          <Card className={style.authCard}>
            <CardBody className={style.authCard__body}>{children}</CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
