import style from "./index.module.scss";
import classNames from "classnames";
import { useEffect } from "react";
import { clearInterval } from "timers";
interface IProps {
  type?: "success" | "error";
  text: string;
  onClose: () => void;
}

export function Alert({ type, text, onClose }: IProps) {
  useEffect(() => {
    let timeout = setTimeout(() => {
      onClose();
    }, 4000);
    return () => {
      clearTimeout(timeout);
    };
  });
  return (
    <div
      className={classNames(style.alert, type ? style[type] : style.success)}
    >
      {text}
      <i className={"fa fa-close"}></i>
    </div>
  );
}
