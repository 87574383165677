import { IObserverDetailParams } from "@/models";
import { computeParams } from "@/utils/to-params";
import axiosClient from "@/libs/axiosClient";

enum API_URL {
  subaccounts = "/api/v1/pools/sub_account/observer_link/sub_accounts/",
  chart = "/api/v1/pools/sub_account/observer_link/create/",
  detail = "/api/v1/pools/sub_account/observer_link/detail/",
}
export const getDetailWatcherLink = (
  token: string,
  params?: IObserverDetailParams
) => {
  return axiosClient
    .get(API_URL.detail + `${token}/`, { params: params ?? {} })
    .then((res) => res.data);
};
export const getWatcherLinkSubAccounts = (token: string) => {
  return axiosClient.get(API_URL.subaccounts + `${token}/`);
};
