import React from "react";

import { ButtonGroup, Dropdown, DropdownButton, Form } from "react-bootstrap";

const CheckDropdownItem = React.forwardRef(
  ({ children, id, checked, onChange }: any, ref: any) => {
    return (
      <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
        <Form.Check
          type="checkbox"
          label={children}
          checked={checked}
          onChange={onChange && onChange.bind(onChange, id)}
        />
      </Form.Group>
    );
  },
);

export const CheckboxDropdown = ({ items, onChange, label }: any) => {
  return (
    <DropdownButton
      as={ButtonGroup}
      key={"btn"}
      className={"custom-dropdown"}
      id={`dropdown-variants-Secondary`}
      variant={"secondary"}
      title={label || "Select"}
    >
      {items.map((i: any) => (
        <Dropdown.Item
          key={i.id}
          as={CheckDropdownItem}
          id={i.id}
          checked={i.checked}
          onChange={onChange as any}
        >
          {i.label}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};
