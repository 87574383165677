import { AuthLayout } from "@/layouts/auth-layout";
import classNames from "classnames";
import style from "@/pages/auth/index.module.scss";
import { Input } from "@/components/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslations } from "use-intl";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  resendPassword,
  resendPasswordCode,
  resendPasswordEmail,
  verifySend,
} from "@/store/auth/api";
import { useCode } from "@/hooks/use-code";
import { USER_INFO } from "@/variables";

function ResetForm({
  body,
  callback,
}: {
  body: { email: string; reset_token: string };
  callback: () => void;
}) {
  const [error, setError] = useState("");
  const i18n = useTranslations("reset");

  const formik = useFormik({
    validationSchema: Yup.object({
      password: Yup.string().required("reset.error.fill"),
      password2: Yup.string()
        .required("error.fill")
        .oneOf([Yup.ref("password")], "reset.error.password"),
    }),
    validateOnChange: false,
    initialValues: {
      password: "",
      password2: "",
    },
    onSubmit: (values) => {
      setError("");
      resendPassword({
        ...body,
        password2: values.password2,
        password: values.password,
      })
        .then(() => {
          callback();
        })
        .catch(() => {
          setError("reset.reset.error.chars");
        });
    },
  });
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
    >
      <Input
        placeholder={i18n("reset.password")}
        value={formik.values.password}
        type={"password"}
        onChange={formik.handleChange}
        error={formik.errors.password ? i18n(formik.errors.password) : ""}
        name={"password"}
      />
      <Input
        placeholder={i18n("reset.password2")}
        value={formik.values.password2}
        error={formik.errors.password2 ? i18n(formik.errors.password2) : ""}
        type={"password"}
        onChange={formik.handleChange}
        name={"password2"}
      />
      {error && <span className={style.error}>{i18n("error")}</span>}
      <button className="btn btn-primary w-100" type="submit">
        {i18n("resetBtn")}
      </button>
    </form>
  );
}

function EmailForm({
  callbackEmail,
}: {
  callbackEmail: (val: string) => void;
}) {
  const [error, setError] = useState("");
  const i18n = useTranslations("reset");

  const formik = useFormik({
    validationSchema: Yup.object({
      email: Yup.string().required("error.fill"),
    }),
    validateOnChange: false,
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      resendPasswordEmail(values.email)
        .then(() => {
          callbackEmail(values.email);
        })
        .catch(() => {
          setError("email.error.invalid");
        });
    },
  });
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
    >
      <Input
        placeholder={"Email"}
        value={formik.values.email}
        onChange={formik.handleChange}
        name={"email"}
      />
      {error && <span className={style.error}>{i18n(error)}</span>}
      <button className="btn btn-primary w-100 mt-2" type="submit">
        {i18n("send")}
      </button>
    </form>
  );
}

function CodeForm({
  email,
  callback,
}: {
  email: string;
  callback: (code: string) => void;
}) {
  const [error, setError] = useState("");
  const i18n = useTranslations("reset");
  const { clearTimer, startTime, timer } = useCode();

  useEffect(() => {
    startTime();
    return () => {
      clearTimer();
    };
  }, []);

  const formik = useFormik({
    validationSchema: Yup.object({
      code: Yup.string().required("code.error.fill"),
    }),
    validateOnChange: false,
    initialValues: {
      code: "",
    },
    onSubmit: (values) => {
      resendPasswordCode({ email, code: values.code })
        .then((res) => {
          callback(res.reset_token);
        })
        .catch(() => {
          setError("code.error.invalid");
        });
    },
  });
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
    >
      <Input
        placeholder={"Code"}
        value={formik.values.code}
        onChange={formik.handleChange}
        name={"code"}
      />
      {error && <span className={style.error}>{i18n(error)}</span>}
      <p className={style.authCard__body__timer}>
        {timer === 0 ? (
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault();
              resendPasswordEmail(email).then(() => {
                startTime();
              });
            }}
          >
            {i18n("sendAgain")}
          </a>
        ) : (
          <>
            <span>{i18n("againAlert")}</span>: 00:
            {timer < 10 ? `0${timer}` : timer}
          </>
        )}
      </p>
      <button className="btn btn-primary w-100 mt-2" type="submit">
        {i18n("resetBtn")}
      </button>
    </form>
  );
}

const enum EStep {
  EMAIL = "email",
  CODE = "code",
  RESET = "reset",
}

export default function ResetPage() {
  const i18n = useTranslations("reset");
  const navigate = useNavigate();
  const [step, setStep] = useState(EStep.EMAIL);
  const [form, setForm] = useState({
    email: "",
    reset_token: "",
  });

  useEffect(() => {
    return () => {};
  }, []);

  const getForm = () => {
    switch (step) {
      case EStep.CODE:
        return (
          <CodeForm
            email={form.email}
            callback={(code) => {
              setForm({ ...form, reset_token: code });
              setStep(EStep.RESET);
            }}
          />
        );
      case EStep.RESET:
        return (
          <ResetForm
            body={form}
            callback={() => {
              navigate("/login");
            }}
          />
        );
      default:
        return (
          <EmailForm
            callbackEmail={(val) => {
              setForm({ ...form, email: val });
              setStep(EStep.CODE);
            }}
          />
        );
    }
  };
  const getTitle = () => {
    switch (step) {
      case EStep.CODE:
        return i18n("code.title");
      case EStep.RESET:
        return i18n("reset.title");
      default:
        return i18n("email.title");
    }
  };
  return (
    <AuthLayout>
      <div className={classNames("text-center", style.authCard__title)}>
        <h3>{getTitle()}</h3>
        <div>
          <span className="text-success">
            <i className="fal fa-lock mr-1"></i>https://
          </span>
          btcpool.kz
        </div>
      </div>
      <hr className="border-top-gray my-4" />
      {getForm()}
    </AuthLayout>
  );
}
