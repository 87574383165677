import { Card } from "@/components/Card";
import { Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "@/store";
import {
  getWatcherLinkDetail$,
  getWatcherLinkSubAccounts$,
} from "@/store/observers";
import { useSelector } from "react-redux";
import { useChart } from "@/hooks/use-chart";
import Chart from "react-apexcharts";
import { CustomTable } from "@/components/Table/CustomTable";
import { extractDate, toDate } from "@/utils";
import { formatHash } from "@/utils/to-hash";
import { useTranslations } from "use-intl";
import { IObserverDetailParams } from "@/models";

export default function CustomObserverLinkPage() {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const { watcherLoading, watcherLink, watchLinkSubAccount } = useSelector(
    (state: RootState) => state.observer,
  );
  const [params, setParams] = useState<IObserverDetailParams>({
    start_date: extractDate(
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
    ),
    end_date: extractDate(new Date()),
    sub_account_name: "",
  });
  const i18n = useTranslations("cabinet");
  const { chart, setChartByInterval } = useChart();
  useEffect(() => {
    if (!token) {
      return;
    }
    dispatch(getWatcherLinkSubAccounts$(token)).unwrap().then();
  }, [token]);
  useEffect(() => {
    if (!watchLinkSubAccount) {
      return;
    }
    setParams({ ...params, sub_account_name: watchLinkSubAccount.name });
  }, [watchLinkSubAccount]);
  useEffect(() => {
    if (!token || !params.sub_account_name) {
      return;
    }
    dispatch(getWatcherLinkDetail$({ token, params }))
      .unwrap()
      .then((res: any) => {
        if (res && res.hashrate_chart) {
          setChartByInterval(res.hashrate_chart.interval, res.hashrate_chart);
        }
      });
  }, [params]);
  return (
    <div className={"container pt-5"}>
      {watcherLoading ? (
        <div className={"text-center"}>
          <Spinner />
        </div>
      ) : (
        watcherLink && (
          <div>
            <h2 className={"text-black"}>Ссылка наблюдателя</h2>
            <div className={"mt-4"}>
              <Row>
                {watcherLink.statistics && (
                  <div className={"col-lg-4"}>
                    <Card className={"p-3"}>
                      <span>Средний хешрейт за 10 минут</span>
                      <h2>{formatHash(watcherLink.statistics.hashrate_10m)}</h2>
                      <Row>
                        <div className="col-6">
                          <p className="font-weight-bold text-black mb-0">
                            {formatHash(watcherLink.statistics.hashrate_1h)}
                          </p>
                          <div className="mt-1 split-line orange"></div>
                          <p>За час</p>
                        </div>
                        <div className="col-6">
                          <p className="font-weight-bold text-black mb-0">
                            {formatHash(watcherLink.statistics.hashrate_24h)}
                          </p>
                          <div className="mt-1 split-line orange"></div>
                          <p>За день</p>
                        </div>
                      </Row>
                    </Card>
                  </div>
                )}
                <div className={"col-lg-4"}>
                  <Card className={"p-3"}>
                    <span>Баланс</span>
                    <h2>{watcherLink.balance || 0} BTC</h2>
                    <Row>
                      <div className="col-6">
                        <p className="font-weight-bold text-black mb-0">{0}</p>
                        <div className="mt-1 split-line green"></div>
                        <p>Общий доход</p>
                      </div>
                      <div className="col-6">
                        <p className="font-weight-bold text-black mb-0">{0}</p>
                        <div className="mt-1 split-line green"></div>
                        <p>Выплаты</p>
                      </div>
                    </Row>
                  </Card>
                </div>
                {watcherLink.statistics && (
                  <div className={"col-lg-4"}>
                    <Card className={"p-3"}>
                      <span>Активные майнеры</span>
                      <h2>{watcherLink.statistics.online_workers}</h2>
                      <Row>
                        <div className="col-6">
                          <p className="font-weight-bold text-black mb-0">
                            {watcherLink.statistics.offline_workers}
                          </p>
                          <div className="mt-1 split-line red"></div>
                          <p>Не активные</p>
                        </div>
                      </Row>
                    </Card>
                  </div>
                )}
              </Row>
            </div>
            {watcherLink.hashrate_chart && (
              <>
                <h4 className={"text-black"}>График хешрейта</h4>
                <div className={"mt-4"}>
                  <div id="chart">
                    <div id="chart-timeline">
                      <Chart
                        options={chart.options as any}
                        series={chart.series}
                        type="area"
                        height={350}
                      />
                    </div>
                  </div>
                  <div id="html-dist"></div>
                </div>
              </>
            )}
            {watcherLink.earnings && (
              <>
                <h4 className={"text-black mt-4"}>История дохода</h4>
                <div className={"mt-4"}>
                  <CustomTable
                    notFoundText={i18n("notFoundText")}
                    header={[
                      i18n("earnings.header.date"),
                      i18n("earnings.header.profit"),
                      i18n("earnings.header.hashrate"),
                    ]}
                    body={watcherLink.earnings.map((earning) => {
                      return ["date", "total_profit", "hashrate"].map((key) => {
                        if (key === "date") {
                          return toDate(earning[key]);
                        } else if (key === "hashrate") {
                          return formatHash(earning[key]);
                        }
                        return earning[key].toFixed(8);
                      });
                    })}
                  />
                </div>
              </>
            )}
            {watcherLink.payouts && (
              <>
                <h4 className={"text-black mt-4"}>Выплаты</h4>
                <div className={"mt-4"}>
                  <CustomTable
                    notFoundText={i18n("notFoundText")}
                    header={[
                      i18n("payout.header.date"),
                      i18n("payout.header.amount"),
                      i18n("payout.header.address"),
                      "TxID",
                    ]}
                    body={watcherLink.payouts.map((payout: any) => {
                      return ["paid_date", "amount", "address", "tx_id"].map(
                        (key) => {
                          if (key === "paid_date") {
                            return toDate(payout[key]);
                          } else if (key === "amount") {
                            return payout[key].toFixed(8);
                          }
                          return payout[key];
                        },
                      );
                    })}
                  />
                </div>
              </>
            )}
          </div>
        )
      )}
    </div>
  );
}
