import { useTranslations } from "use-intl";
import { Card } from "@/components/Card";
import { Button, Form, Row } from "react-bootstrap";
import { CheckboxDropdown, Input } from "@/components/Input";
import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "@/store";
import { useSubAccount } from "@/hooks";
import { useFormik } from "formik";
import { IObserverCreate } from "@/models";
import { createObserver$, getAllObservers$ } from "@/store/observers";
import { CustomTable } from "@/components/Table/CustomTable";
import style from "./index.module.scss";
import { useSelector } from "react-redux";

const ENABLED_ABILITY: Partial<{
  [key in Partial<keyof Partial<IObserverCreate>>]: string;
}> = {
  payouts_enabled: "form.payoutEnabled",
  earnings_enabled: "form.earningEnabled",
  balance_enabled: "form.balanceEnabled",
  statistics_enabled: "form.statisticEnabled",
  hashrate_chart_enabled: "form.graphic",
};

export default function ObserversPage() {
  const i18n = useTranslations("cabinet.observers");
  const dispatch = useAppDispatch();
  const { subAccounts } = useSubAccount();
  const observers = useSelector((state: RootState) => state.observer.observers);
  const formik = useFormik<IObserverCreate>({
    initialValues: {
      name: "",
      sub_account_ids: [],
      payouts_enabled: false,
      earnings_enabled: false,
      balance_enabled: false,
      statistics_enabled: false,
      hashrate_chart_enabled: false,
    },
    validateOnChange: false,
    onSubmit: (values: any) => {
      dispatch(createObserver$(values))
        .unwrap()
        .then(() => {});
    },
  });
  const [accounts, setAccounts] = useState<any>([]);
  useEffect(() => {
    setAccounts(
      subAccounts.map((res: any) => {
        return { ...res, label: res.name, checked: false };
      })
    );
  }, [subAccounts]);
  useEffect(() => {
    dispatch(getAllObservers$()).unwrap();
  }, []);
  return (
    <>
      <h5 className={"text-black"}>{i18n("title")}</h5>
      <Card className={"mt-4"}>
        <Row>
          <div className={"col-lg-5 p-5"}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <Input
                label={i18n("form.name")}
                name={"name"}
                onChange={formik.handleChange}
              />
              <CheckboxDropdown
                items={accounts}
                label={
                  accounts
                    .filter((acc: any) => acc.checked)
                    .map((item: any) => item.name)
                    .toString() || i18n("form.chooseSubAccount")
                }
                onChange={(e: any) => {
                  const acc = accounts.find((acc: any) => acc.id === e);
                  acc.checked = !acc.checked;
                  formik.values.sub_account_ids = accounts
                    .filter((res: any) => res.checked)
                    .map((res: any) => res.id);
                  setAccounts([...accounts]);
                }}
              />
              <div className={"mt-4"}>
                <Form.Check
                  type="switch"
                  className={"switch"}
                  label={i18n(ENABLED_ABILITY.balance_enabled)}
                  name={"balance_enabled"}
                  onChange={formik.handleChange}
                />
                <Form.Check
                  type="switch"
                  className={"switch"}
                  label={i18n(ENABLED_ABILITY["statistics_enabled"])}
                  name={"statistics_enabled"}
                  onChange={formik.handleChange}
                />
                <Form.Check
                  type="switch"
                  className={"switch"}
                  label={i18n(ENABLED_ABILITY.earnings_enabled)}
                  name={"earnings_enabled"}
                  onChange={formik.handleChange}
                />
                <Form.Check
                  type="switch"
                  className={"switch"}
                  label={i18n(ENABLED_ABILITY.payouts_enabled)}
                  name={"payouts_enabled"}
                  onChange={formik.handleChange}
                />
                <Form.Check
                  type="switch"
                  className={"switch"}
                  label={i18n(ENABLED_ABILITY.hashrate_chart_enabled)}
                  name={"hashrate_chart_enabled"}
                  onChange={formik.handleChange}
                />
              </div>
              <div className={"text-center"}>
                <Button type={"submit"} className={"mt-3"}>
                  {i18n("create")}
                </Button>
              </div>
            </form>
          </div>
          <div className={"col-lg-7 p-4"}>
            <CustomTable
              header={[
                i18n("table.name"),
                i18n("table.access"),
                i18n("table.subaccount"),
                "URL",
              ]}
              body={observers.map((observer: any) => {
                return [
                  observer.name,
                  Object.keys(ENABLED_ABILITY)
                    .filter((key) => observer[key] as string)
                    .map((item) =>
                      i18n(
                        ENABLED_ABILITY[
                          item as Partial<keyof Partial<IObserverCreate>>
                        ]
                      )
                    )
                    .toString(),
                  observer.sub_accounts?.toString(),
                  <a
                    href={`/observer-link/${observer.key}`}
                    target={"_blank"}
                    className={style.observers__arrowIcon}
                    rel="noreferrer"
                  >
                    &rarr;{" "}
                  </a>,
                ];
              })}
              notFoundText={"Not found"}
            />
          </div>
        </Row>
      </Card>
    </>
  );
}
