import { AuthLayout } from "@/layouts/auth-layout";
import classNames from "classnames";
import style from "@/pages/auth/index.module.scss";
import { Input } from "@/components/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslations } from "use-intl";
import { useEffect, useRef, useState } from "react";
import { refreshToken, verifyCode, verifySend } from "@/store/auth/api";
import { useNavigate } from "react-router-dom";
import { USER_INFO, USER_TOKEN } from "@/variables";
import { customFetch } from "@/utils/custom-fetch";
import { useAuth } from "@/hooks";
import { useCode } from "@/hooks/use-code";

export default function VerifyPage() {
  const i18n = useTranslations("verify");
  const [error, setError] = useState("");
  const { startTime, clearTimer, timer } = useCode();
  const navigate = useNavigate();
  const { setUserStore } = useAuth();

  const formik = useFormik({
    validationSchema: Yup.object({
      code: Yup.string().required("error.fill"),
    }),
    validateOnChange: false,
    initialValues: {
      code: "",
    },
    onSubmit: () => {},
  });
  useEffect(() => {
    startTime();
    return () => {
      clearTimer();
    };
  }, []);
  const onVerifyClick = () => {
    const token = JSON.parse(
      localStorage.getItem(USER_INFO) || JSON.stringify({}),
    );
    verifyCode(formik.values.code, token?.user?.email)
      .then(async () => {
        const tokenStorage = localStorage.getItem(USER_TOKEN);
        const currentToken = tokenStorage ? JSON.parse(tokenStorage) : {};
        const resp = await refreshToken({ refresh: currentToken.refresh });

        localStorage.setItem(USER_TOKEN, JSON.stringify(resp));
        customFetch.defaults.headers.common["Authorization"] =
          `Bearer ${resp.access}`;
        setUserStore(resp).then(() => {
          token.user.is_email_verified = true;
          localStorage.setItem(USER_INFO, JSON.stringify(token));
          navigate("/cabinet/statistics");
        });
      })
      .catch((e) => {
        setError(i18n("error.invalid"));
      });
  };
  return (
    <AuthLayout>
      <div className={classNames("text-center", style.authCard__title)}>
        <h3>{i18n("title")}</h3>
        <div>
          <span className="text-success">
            <i className="fal fa-lock mr-1"></i>https://
          </span>
          btcpool.kz
        </div>
      </div>
      <hr className="border-top-gray my-4" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <Input
          placeholder={i18n("code")}
          value={formik.values.code}
          onChange={formik.handleChange}
          name={"code"}
        />
        {error && <span className={style.error}>{error}</span>}
        <p className={style.authCard__body__timer}>
          {timer === 0 ? (
            <a
              href={"#"}
              onClick={(e) => {
                e.preventDefault();
                const token = JSON.parse(
                  localStorage.getItem(USER_INFO) || JSON.stringify({}),
                );
                verifySend(token?.user?.email).then(() => {
                  startTime();
                });
              }}
            >
              {i18n("sendAgain")}
            </a>
          ) : (
            <>
              <span>{i18n("againAlert")}</span>: 00:
              {timer < 10 ? `0${timer}` : timer}
            </>
          )}
        </p>
        <button
          className="btn btn-primary w-100"
          type="submit"
          onClick={() => onVerifyClick()}
        >
          {i18n("sendBtn")}
        </button>
      </form>
    </AuthLayout>
  );
}
