import { InputHTMLAttributes } from "react";
import style from "./index.module.scss";
import classNames from "classnames";

interface IProps extends InputHTMLAttributes<any> {
  label?: any;
  error?: string;
}

export function Input(props: IProps) {
  return (
    <div className={style.formGroup}>
      {props.label}
      <input className={classNames("form-control", style.input)} {...props} />
      {props.error && <span className={style.error}>{props.error}</span>}
    </div>
  );
}
