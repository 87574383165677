import React from "react";
import { Header, Table } from "@/components";
import style from "./index.module.scss";
import classNames from "classnames";
import { Card } from "@/components/Card";
import { Footer } from "@/components/Footer";
import { useTranslations } from "use-intl";

const THIRD_BLOCK_LIST = [
  {
    heading: "thirdBlock.effective.title",
    description: "thirdBlock.effective.description",
    image: require("@/images/main/statistic/effective.svg").default,
  },
  {
    heading: "thirdBlock.payout.title",
    description: "thirdBlock.payout.description",
    image: require("@/images/main/statistic/payouts.svg").default,
  },
  {
    heading: "thirdBlock.safe.title",
    description: "thirdBlock.safe.description",
    image: require("@/images/main/statistic/safe.svg").default,
  },
  {
    heading: "thirdBlock.mobapp.title",
    description: "thirdBlock.mobapp.description",
    image: require("@/images/main/statistic/mobile-app.svg").default,
  },
];

export default function MainPage() {
  const i18n = useTranslations("main");
  return (
    <>
      <Header className={style.headerClass}></Header>
      <section className={style.firstSection}>
        <div className="container">
          <div
            className={classNames(
              "text-center text-white",
              style.firstSection__content,
            )}
          >
            <h1>{i18n("firstBlock.title")}</h1>
            <p className={"mt-3"}>{i18n("firstBlock.subtitle")}</p>
          </div>
        </div>
        <div className={style.firstSection__bgAnimation}></div>
        <svg
          className={style.firstSection__svg}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
        </svg>
      </section>
      <section className={style.secondSection}>
        <div className={"container"}>
          <div className={style.secondSection__card}>
            <Card className={style.secondSection__card__overflow}>
              <Table />
            </Card>
          </div>
        </div>
      </section>
      <section className={style.thirdSection}>
        <div className={classNames("container", style.thirdSection__container)}>
          <div className={style.thirdSection__left}>
            {THIRD_BLOCK_LIST.map((item, idx) => {
              return (
                <div key={idx} className={style.thirdSection__left__content}>
                  <div>
                    <img src={item.image} alt="" />
                  </div>
                  <div>
                    <h2>{i18n(item.heading)}</h2>
                    <p>{i18n(item.description)}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={style.thirdSection__right}>
            <img src={require("@/images/main/app-img-ru.png")} alt={"img"} />
          </div>
        </div>
      </section>
      <section className={style.fourthSection}>
        <div className={"container"}>
          <div className={style.fourthSection__heading}>
            <h1>{i18n("fourthBlock.title")}</h1>
            <p>{i18n("fourthBlock.subtitle")}</p>
          </div>
          <div className={style.fourthSection__content}>
            <div className={"col-lg-4 col-md-6"}>
              <Card>
                <div>
                  <i className="fal fa-user-lock"></i>
                </div>
                <div>
                  <h3>{i18n("fourthBlock.safe.title")}</h3>
                  <p>{i18n("fourthBlock.safe.description")}</p>
                </div>
              </Card>
            </div>
            <div className={"col-lg-4 col-md-6"}>
              <Card>
                <div>
                  <i className="fal fa-piggy-bank"></i>
                </div>
                <div>
                  <h3>{i18n("fourthBlock.earning.title")}</h3>
                  <p>{i18n("fourthBlock.safe.description")}</p>
                </div>
              </Card>
            </div>
            <div className={"col-lg-4 col-md-6"}>
              <Card>
                <div>
                  <i className="fal fa-user-check"></i>
                </div>
                <div>
                  <h3>{i18n("fourthBlock.legal.title")}</h3>
                  <p>{i18n("fourthBlock.legal.description")}</p>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section className={style.fifthSection}>
        <div className={"container"}>
          <div className={style.fifthSection__content}>
            <div className={"col-lg-4 col-md-6"}>
              <div>
                <h2>24/7</h2>
                <p>{i18n("fifthBlock.support")}</p>
              </div>
            </div>
            <div className={"col-lg-4 col-md-6"}>
              <div>
                <h2>20 ms</h2>
                <p>{i18n("fifthBlock.lowPing")}</p>
              </div>
            </div>
            <div className={"col-lg-4 col-md-6"}>
              <div>
                <h2>0%</h2>
                <p>{i18n("fifthBlock.lowPing")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.firstSection__bgAnimation}></div>
      </section>
      <section className={classNames("section-padding", style.sixthSection)}>
        <div className={"container"}>
          <h2>{i18n("sixthBlock.title")}</h2>
          <p>{i18n("sixthBlock.subtitle")}</p>
          <a href="" className={"btn btn-primary"}>
            {i18n("sixthBlock.btn")}
          </a>
        </div>
      </section>
      <Footer />
    </>
  );
}
