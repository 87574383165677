import { useSelector } from "react-redux";
import { RootState } from "@/store";

export function useSubAccount() {
  const currentSubAccount = useSelector(
    (state: RootState) => state.subAccount.currentSubAccount,
  );
  const loading = useSelector((state: RootState) => state.subAccount.loading);
  const { watcherLinkSubAccounts, watchLinkSubAccount } = useSelector(
    (state: RootState) => state.observer,
  );
  const subAccounts = useSelector(
    (state: RootState) => state.subAccount.subAccounts,
  );
  return {
    watcherLinkSubAccounts,
    watchLinkSubAccount,
    currentSubAccount,
    subAccounts,
    loading,
  };
}
