import { useEffect, useState } from "react";

export function on(obj: any, ...args: any) {
  obj.addEventListener(...args);
}

export function off(obj: any, ...args: any) {
  obj.removeEventListener(...args);
}

/**
 * useScrollingUp custom react hook
 * @returns {boolean}
 */
const useScrollingUp = () => {
  const [scrollingUp, setScrollingUp] = useState(false);
  const handleScroll = () => {
    const currScroll = window.pageYOffset;
    setScrollingUp(currScroll > 80);
  };
  useEffect(() => {
    on(window, "scroll", handleScroll, { passive: true });
    return () => {
      off(window, "scroll", handleScroll, { passive: true });
    };
  }, []);
  return scrollingUp;
};

export { useScrollingUp };
