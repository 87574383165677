import { CustomDialog } from "@/components/Dialog";
import { Form, Modal, Spinner } from "react-bootstrap";
import { Input } from "@/components/Input";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/store";
import { getEarningScheme$ } from "@/store/references";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createSubAccount$ } from "@/store/sub_accounts";
import { useTranslations } from "use-intl";

const ERROR_CODE: any = {
  4014: "wallet",
  5010: "unexpected",
  4013: "alreadyExist",
  4011: "currency",
  4010: "earningScheme",
  4012: "alreadyExist",
};

export function AddSubAccountDialog({ onClose }: { onClose: () => void }) {
  const [error, setError] = useState("");
  const [show, setShow] = useState(true);
  const i18n = useTranslations("cabinet");
  const formik = useFormik({
    initialValues: {
      crypto_currency: "",
      wallet_address: "",
      earning_scheme_id: "",
      name: "",
    },
    validationSchema: Yup.object({
      crypto_currency: Yup.string().required("Заполните это поле"),
      wallet_address: Yup.string().required("Заполните это поле"),
      earning_scheme_id: Yup.string().required("Заполните это поле"),
      name: Yup.string().required("Заполните это поле"),
    }),
    validateOnChange: false,
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      dispatch(createSubAccount$(formik.values as any)).then((e: any) => {
        formikHelpers.setSubmitting(false);
        if (e.error) {
          setError(`addSubAccount.form.${ERROR_CODE[e.error?.message]}`);
          return;
        }
        setShow(false);
      });
    },
  });
  const references = useSelector((state: RootState) => state.references);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getEarningScheme$()).then(() => {
      setFieldValues();
    });
  }, []);
  useEffect(() => {
    let timeout: any;
    if (!show) {
      timeout = setTimeout(() => {
        onClose();
      }, 100);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [show]);
  const setFieldValues = () => {
    if (references.currencies && references.currencies.length) {
      formik.values.crypto_currency = references.currencies[0];
    }
    if (references.earningScheme && references.earningScheme.length) {
      formik.values.earning_scheme_id = references.earningScheme[0].id;
    }
  };
  useEffect(() => {
    setFieldValues();
  }, [references.earningScheme, references.currencies]);
  return (
    <CustomDialog
      onClose={() => setShow(false)}
      show={show}
      body={
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <Input
            placeholder={i18n("addSubAccount.name")}
            name={"name"}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.errors.name}
          />
          <Form.Select
            value={formik.values.crypto_currency}
            onChange={formik.handleChange}
            name={"crypto_currency"}
            className={"form--control form-control mb-3 p-2"}
            size={"sm"}
          >
            {references.currencies.map((item, idx) => (
              <option value={item} key={idx}>
                {item}
              </option>
            ))}
          </Form.Select>
          <Input
            placeholder={i18n("addSubAccount.wallet")}
            name={"wallet_address"}
            onChange={formik.handleChange}
            value={formik.values.wallet_address}
            error={formik.errors.wallet_address}
          />
          <Form.Select
            value={formik.values.earning_scheme_id}
            className={"form--control form-control"}
            onChange={formik.handleChange}
            name={"earning_scheme_id"}
            size={"sm"}
          >
            {references.earningScheme.map((item, idx) => (
              <option value={item.id} key={idx}>
                {item.earning_scheme}
              </option>
            ))}
          </Form.Select>
          {error && (
            <div className={"btn-danger text-danger mt-4"}>{i18n(error)}</div>
          )}
          {formik.isSubmitting ? (
            <Spinner animation={"grow"} />
          ) : (
            <button
              className={"btn btn-sm p-2 btn-primary mt-3"}
              type={"submit"}
            >
              {i18n("addSubAccount.btn")}
            </button>
          )}
        </form>
      }
      heading={<Modal.Title>{i18n("addSubAccount.title")}</Modal.Title>}
    />
  );
}
