import { useTranslations } from "use-intl";

export default function TariffPage() {
  const i18n = useTranslations("tariff");
  return (
    <section className="pt-5">
      <div className="container">
        <h3 className="font-weight-bold mb-5">{i18n("title")}</h3>
        <div>
          <div className="container">
            <h2 className="mb-3">{i18n("title2")}</h2>
            <p>{i18n("content")}</p>
            <p>{i18n("content2")}</p>
            <h3 className="mt-3 mb-3">{i18n("title3")}</h3>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>{i18n("table.row1.col1")}</td>
                  <td>{i18n("table.row1.col2")}</td>
                  <td>{i18n("table.row1.col3")}</td>
                </tr>
                <tr>
                  <td>{i18n("table.row2.col1")}</td>
                  <td>PPS</td>
                  <td>PPLNS</td>
                </tr>
                <tr>
                  <td>{i18n("table.row3.col1")}</td>
                  <td>4%</td>
                  <td>0.7%</td>
                </tr>
                <tr>
                  <td>{i18n("table.row4.col1")}</td>
                  <td>{i18n("table.row4.col2")}</td>
                  <td>{i18n("table.row4.col3")}</td>
                </tr>
                <tr>
                  <td>{i18n("table.row5.col1")}</td>
                  <td>{i18n("table.row5.col2")}</td>
                  <td>{i18n("table.row5.col3")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}
