import style from "./index.module.scss";
import classNames from "classnames";

interface IProps {
  header: any[];
  body: string[][];
  notFoundText: string;
}

export function CustomTable({ header, body, notFoundText }: IProps) {
  return (
    <div className={classNames(style.wrapper, style.table)}>
      <table className={style.customTable}>
        <thead>
          <tr>
            {header.map((head, idx) => (
              <th key={idx}>{head}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.map((row, idx) => (
            <tr key={idx} className={idx % 2 ? style.odd : style.even}>
              {row.map((col, idx) => (
                <td key={idx}>{col}</td>
              ))}
            </tr>
          ))}
          {body.length === 0 && (
            <tr className={"text-center"}>
              <td colSpan={5}>{notFoundText ? notFoundText : "Нет данных"}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
