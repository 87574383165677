import { Container, Form } from "react-bootstrap";
import style from "./index.module.scss";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useScrollingUp } from "@/utils";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@/store";
import React, { useEffect } from "react";
import {
  getAllSubAccounts$,
  setCurrentSubAccount,
  setShowModal,
} from "@/store/sub_accounts";
import { useAuth, useSubAccount } from "@/hooks";
import { setCurrentUser } from "@/store/auth";
import { useTranslations } from "use-intl";
import { changeWatcherSubAccount } from "@/store/observers";

interface IProps {
  className?: string;
}

export function Header({ className }: IProps) {
  const isScrolled = useScrollingUp();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const {
    currentSubAccount,
    subAccounts,
    watcherLinkSubAccounts,
    watchLinkSubAccount,
  } = useSubAccount();
  const dispatch = useAppDispatch();
  const i18n = useTranslations("header");
  const { logout } = useAuth();
  const getButtons = () => {
    if (location.pathname.includes("observer-link")) {
      return (
        <>
          {watcherLinkSubAccounts.length && watchLinkSubAccount ? (
            <Form.Select
              value={watchLinkSubAccount.name}
              onChange={(val) => {
                dispatch(
                  changeWatcherSubAccount(
                    watcherLinkSubAccounts.find(
                      (res) => res.name === val.target.value,
                    ),
                  ),
                );
              }}
            >
              {watcherLinkSubAccounts.map((account: any, key: number) => (
                <option value={account.name} key={key}>
                  {account.name}
                </option>
              ))}
            </Form.Select>
          ) : null}
        </>
      );
    }
    if (currentUser) {
      if (!location.pathname.includes("cabinet")) {
        return (
          <>
            <Link
              to={"/cabinet/statistics"}
              className={"btn btn-sm btn-light text-black"}
            >
              {i18n("personalCabinet")}
            </Link>

            <a
              href={"#"}
              className={style.header__right__logout}
              onClick={(e) => {
                e.preventDefault();
                logout().then(() => {
                  dispatch(setCurrentUser(null));
                  navigate("/");
                });
              }}
            >
              <i className="fa fa-sign-out"></i>
            </a>
          </>
        );
      } else {
        return (
          <>
            <button
              className={classNames(
                "btn btn-sm btn-light text-black",
                style.none,
              )}
              onClick={() => dispatch(setShowModal(true))}
            >
              {i18n("addSubAccount")}
            </button>
            {subAccounts.length ? (
              <Form.Select
                value={currentSubAccount}
                onChange={(val) => {
                  dispatch(setCurrentSubAccount(val.target.value));
                }}
              >
                {subAccounts.map((account, key) => (
                  <option value={account.name} key={key}>
                    {account.name}
                  </option>
                ))}
              </Form.Select>
            ) : null}
            <Link
              to={"/cabinet/statistics"}
              className={classNames(
                "btn btn-sm btn-light text-black",
                style.none,
              )}
            >
              {i18n("goToMain")}
            </Link>
            <a
              href="#"
              className={style.header__right__logout}
              onClick={(e) => {
                e.preventDefault();
                dispatch(setCurrentUser(null));
                logout().then(() => navigate("/"));
              }}
            >
              <i className="fa fa-sign-out"></i>
            </a>
          </>
        );
      }
    } else {
      return (
        <div>
          <Link to={"/login"} className={"btn-sm btn"}>
            {i18n("signIn")}
          </Link>
          <Link to={"/signup"} className={"btn btn-sm btn-light text-black"}>
            {i18n("signUp")}
          </Link>
        </div>
      );
    }
  };
  useEffect(() => {
    location.pathname.includes("cabinet") && dispatch(getAllSubAccounts$());
  }, []);
  return (
    <div
      className={classNames(
        style.header__wrapper,
        isScrolled && style.header__fixed,
        className,
      )}
    >
      <div className={style.header__inner}>
        <Container className={"container-lg"}>
          <div className={classNames(style.header, "sticky-top")}>
            <div className={style.header__left}>
              <div onClick={() => navigate("/")}>
                <img
                  src={
                    isScrolled
                      ? require("@/images/main/main-logo-sticky.png")
                      : require("@/images/main/main-logo.png")
                  }
                  alt={"main-icon"}
                />
              </div>
            </div>
            <div className={style.header__right}>{getButtons()}</div>
          </div>
        </Container>
      </div>
    </div>
  );
}
