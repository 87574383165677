import { Input, PasswordInput } from "@/components/Input";
import { Card, CardBody } from "react-bootstrap";
import style from "./index.module.scss";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setCurrentUser, signInAsync } from "@/store/auth";
import { useAppDispatch } from "@/store";
import { useState } from "react";
import { useAuth } from "@/hooks";
import { getAllSubAccounts$ } from "@/store/sub_accounts";
import { useTranslations } from "use-intl";

const ERROR_TEXT: { [key in string]: string } = {
  4001: "error.userDoesntExist",
  4002: "error.wrongPassword",
  5000: "error.exception",
  10002: "error.otpRequired",
  10001: "error.invalid",
  10003: "error.exception",
  10004: "error.exception",
  5003: "error.unknown",
};

export default function Login() {
  const [error, setError] = useState("");
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    validationSchema: Yup.object({
      password: Yup.string().required("error.fill"),
      username: Yup.string().required("error.fill"),
    }),
    validateOnChange: false,
    initialValues: {
      password: "",
      username: "",
      otp_code: "",
    },
    onSubmit: (values) => {
      const body = { ...values };
      console.log(!values.otp_code);
      if (!values.otp_code) {
        // @ts-ignore
        delete body["otp_code"];
      }
      setError("");
      dispatch(signInAsync(body))
        .unwrap()
        .then((res) => {
          dispatch(getAllSubAccounts$());
          auth.setUserStore({ ...res }).then((data) => {
            dispatch(setCurrentUser(data));
            navigate("/cabinet/statistics");
          });
        })
        .catch((e) => {
          setError(e.message);
        });
    },
  });
  const i18n = useTranslations("login");
  return (
    <div className={"section-padding"}>
      <div className={"container"}>
        <div className={"col-lg-6 mx-auto"}>
          <Card className={style.authCard}>
            <CardBody className={style.authCard__body}>
              <div className={classNames("text-center", style.authCard__title)}>
                <h3>{i18n("title")}</h3>
                <p>{i18n("subtitle")}</p>
                <div>
                  <span className="text-success">
                    <i className="fal fa-lock mr-1"></i>https://
                  </span>
                  btcpool.kz
                </div>
              </div>
              <hr className="border-top-gray my-4" />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <Input
                  placeholder={"Email"}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.username ? i18n(formik.errors.username) : ""
                  }
                  name={"username"}
                />
                <PasswordInput
                  placeholder={i18n("form.password")}
                  type={"password"}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.password ? i18n(formik.errors.password) : ""
                  }
                  name={"password"}
                  value={formik.values.password}
                />
                <Input
                  placeholder={i18n("form.code")}
                  onChange={formik.handleChange}
                  name={"otp_code"}
                  value={formik.values.otp_code}
                />
                {error && (
                  <span className={style.error}>
                    {i18n(ERROR_TEXT[error as string])}
                  </span>
                )}
                <div
                  className={classNames(
                    "mb-3 text-center",
                    style.authCard__body__topBtn,
                  )}
                >
                  <a
                    href="/reset"
                    className="btn-link font-size-15 font-weight-normal"
                  >
                    {i18n("forgot")}
                  </a>
                </div>

                <button className="btn btn-primary w-100" type="submit">
                  {i18n("btn")}
                </button>
              </form>
              <p className={style.authCard__body__bottomBtn}>
                {i18n("hint")}
                <Link to="/signup" className="text-color btn-link">
                  {i18n("signUp")}
                </Link>
              </p>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
