import { decodeToken } from "react-jwt";
import { USER_INFO, USER_TOKEN } from "@/variables";

export function useAuth() {
  const setUserStore = (token: { access: string; refresh: string }) => {
    const data: any = decodeToken(token.access);
    if (data) {
      data.created_date = new Date().toISOString();
    }
    localStorage.setItem(USER_INFO, JSON.stringify(data));
    localStorage.setItem(USER_TOKEN, JSON.stringify(token));
    return Promise.resolve(data);
  };

  const logout = () => {
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(USER_INFO);

    return Promise.resolve(true);
  };

  return {
    setUserStore,
    logout,
  };
}
