import { useTranslations } from "use-intl";

export default function AboutUs() {
  const i18n = useTranslations("aboutUs");
  return (
    <div className={"pt-5"}>
      <div className="container">
        <h3 className="font-weight-bold mb-5">{i18n("title")}</h3>
        <div>
          <div dangerouslySetInnerHTML={{ __html: i18n("content") }}></div>
        </div>
      </div>
    </div>
  );
}
