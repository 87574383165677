import { InputHTMLAttributes } from "react";
import classNames from "classnames";
import style from "@/components/Input/index.module.scss";

interface IProps extends InputHTMLAttributes<any> {
  label?: any;
  error?: string;
}
export function PasswordInput(props: IProps) {
  return (
    <div className={style.formGroup}>
      <input
        className={classNames("form-control", style.input)}
        type={"text"}
        {...props}
      />
      {props.error && <span className={style.error}>{props.error}</span>}
    </div>
  );
}
