import { Card, CardBody } from "react-bootstrap";
import style from "@/pages/auth/index.module.scss";
import classNames from "classnames";
import { Checkbox, Input, PasswordInput } from "@/components/Input";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "@/store";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SignupDto } from "@/models";
import { checkBinAsync, signUpAsync } from "@/store/auth";
import debounce from "@/utils/debounce";
import { useTranslations } from "use-intl";

const ERROR_TEXT: { [key in string]: string } = {
  3003: "error.userExists",
  3004: "error.phone",
  4002: "error.bin",
  5000: "error.exception",
};
export default function SignUp() {
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const formik = useFormik({
    validationSchema: Yup.object({
      password: Yup.string().required("error.fill"),
      password2: Yup.string()
        .required("error.fill")
        .oneOf([Yup.ref("password")], "error.password"),
      email: Yup.string().required("error.fill").email("error.email"),
      phone: Yup.string().required("error.fill"),
      organization_bin: Yup.string().required("error.fill"),
      username: Yup.string().required("error.fill"),
    }),
    validateOnChange: false,
    initialValues: {
      password: "",
      username: "",
      email: "",
      phone: "",
      password2: "",
      organization_bin: "",
    } as SignupDto,
    onSubmit: (values) => {
      values.username = values.email;
      dispatch(signUpAsync(values))
        .unwrap()
        .then((res) => {
          navigate("/login");
        })
        .catch((e) => {
          setError(e.message);
        });
    },
  });
  const onBinChange = (e: any) => {
    dispatch(checkBinAsync(e.target.value))
      .unwrap()
      .then((res) => {
        setCompanyName(res.name_ru);
      })
      .catch((err) => {
        // setError(err);
      });
  };
  const i18n = useTranslations("signUp");
  const debounceOnChange = useCallback(
    debounce((e: any) => {
      onBinChange(e);
    }, 300),
    [],
  );
  return (
    <div className={"section-padding"}>
      <div className={"container"}>
        <div className={"col-lg-6 mx-auto"}>
          <Card className={style.authCard}>
            <CardBody className={style.authCard__body}>
              <div className={classNames("text-center", style.authCard__title)}>
                <h3>{i18n("title")}</h3>
                <p>{i18n("subtitle")}</p>
                <div>
                  <span className="text-success">
                    <i className="fal fa-lock mr-1"></i>https://
                  </span>
                  btcpool.kz
                </div>
              </div>
              <hr className="border-top-gray my-4" />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                <Input
                  placeholder={"Email"}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email ? i18n(formik.errors.email) : ""}
                  name={"email"}
                />
                <Input
                  placeholder={i18n("form.name")}
                  onChange={formik.handleChange}
                  error={
                    formik.errors.username ? i18n(formik.errors.username) : ""
                  }
                  value={formik.values.username}
                  name={"username"}
                />
                <Input
                  placeholder={i18n("form.bin")}
                  onChange={(e) => {
                    formik.handleChange(e);
                    debounceOnChange(e);
                  }}
                  error={
                    formik.errors.organization_bin
                      ? i18n(formik.errors.organization_bin)
                      : ""
                  }
                  value={formik.values.organization_bin}
                  name={"organization_bin"}
                />
                <Input
                  placeholder={i18n("form.organization_name")}
                  value={companyName}
                  readOnly={true}
                />
                <Input
                  placeholder={i18n("form.phone")}
                  error={formik.errors.phone ? i18n(formik.errors.phone) : ""}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  name={"phone"}
                />
                <PasswordInput
                  type={"password"}
                  placeholder={i18n("form.password")}
                  error={
                    formik.errors.password ? i18n(formik.errors.password) : ""
                  }
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  name={"password"}
                />
                <PasswordInput
                  placeholder={i18n("form.repeatPassword")}
                  error={
                    formik.errors.password2 ? i18n(formik.errors.password2) : ""
                  }
                  type={"password"}
                  value={formik.values.password2}
                  onChange={formik.handleChange}
                  name={"password2"}
                />
                {error && (
                  <p>
                    <span className={style.error}>
                      {i18n(ERROR_TEXT[error])}
                    </span>
                  </p>
                )}
                <Checkbox id={"id"} required={true} />

                <div
                  className={classNames(
                    style.authCard__body__bottomBtn,
                    "form-group",
                  )}
                >
                  <p
                    className="font-size-13 text-center"
                    dangerouslySetInnerHTML={{ __html: i18n("message") }}
                  ></p>
                </div>

                <button className="btn btn-primary w-100" type="submit">
                  {i18n("btn")}
                </button>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
