import { configureStore } from "@reduxjs/toolkit";
import authSlice from "@/store/auth";
import { referenceReducer } from "@/store/references";
import { apiKeyReducer } from "@/store/api_key";
import { subAccountReducer } from "@/store/sub_accounts";
import { useDispatch } from "react-redux";
import { observerReducer } from "@/store/observers";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    apiKey: apiKeyReducer,
    subAccount: subAccountReducer,
    observer: observerReducer,
    references: referenceReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
