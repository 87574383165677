import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CREATE_API_KEY,
  DELETE_API_KEY,
  GET_ALL_API_KEY,
} from "@/store/api_key/variables";
import { addApiKey, deleteApiKey, getAllApiKey } from "@/store/api_key/api";

export interface IApiKey {
  loading: boolean;
  apiKeys: Array<any>;
}
const initialState: IApiKey = {
  loading: false,
  apiKeys: [],
};

const getAllApiKeys$ = createAsyncThunk(
  GET_ALL_API_KEY,
  async (name: string) => {
    const response = await getAllApiKey(name);
    return Promise.resolve(response.data);
  },
);

const createApiKey$ = createAsyncThunk(CREATE_API_KEY, async (name: string) => {
  const response = (await addApiKey(name)) as any;
  return Promise.resolve(response.data.api_key);
});

const deleteApiKey$ = createAsyncThunk(
  DELETE_API_KEY,
  async (payload: { sub_account_name: string; api_key: string }) => {
    await deleteApiKey(payload);
    return Promise.resolve(payload.api_key);
  },
);

export const apiKeySlice = createSlice({
  name: "reference",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllApiKeys$.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllApiKeys$.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllApiKeys$.fulfilled, (state, action) => {
      state.apiKeys = action.payload as any;
      state.loading = false;
    });
    builder.addCase(createApiKey$.fulfilled, (state, action) => {
      state.apiKeys.push(action.payload);
    });
    builder.addCase(deleteApiKey$.fulfilled, (state, action) => {
      state.apiKeys = state.apiKeys.filter((res) => res !== action.payload);
    });
  },
});

export const apiKeyReducer = apiKeySlice.reducer;

export { deleteApiKey$, createApiKey$, getAllApiKeys$ };
