import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";

interface IProps {
  onClose: (v: boolean) => void;
  heading: any;
  body: any;
  size?: "lg" | "xl" | "sm";
  show: boolean;
}

export function CustomDialog({ onClose, show, body, heading, size }: IProps) {
  return createPortal(
    <Modal show={show} size={size} onHide={() => onClose(true)}>
      <Modal.Header closeButton>{heading}</Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>,
    document.body,
  );
}
