import { InputHTMLAttributes } from "react";
import style from "./index.module.scss";
import classNames from "classnames";
import { useTranslations } from "use-intl";

interface IProps extends InputHTMLAttributes<any> {}
export function Checkbox(props: IProps) {
  const i18n = useTranslations("signUp");
  return (
    <div className={style.formGroup}>
      <div className={classNames(style.customControl)}>
        <input type="checkbox" className={style.input__checkbox} {...props} />
        <label
          className={classNames("font-weight-normal", style.label)}
          htmlFor={props.id}
          dangerouslySetInnerHTML={{ __html: i18n("checkbox") }}
        ></label>
      </div>
    </div>
  );
}
