import classNames from "classnames";

interface ICard {
  children: any;
  className?: string;
}
export function Card(props: ICard) {
  return (
    <div className={classNames("card", props.className)}>{props.children}</div>
  );
}
