import { Component } from "react";

class ErrorBoundary extends Component {
  state = {
    error: null,
  };
  static getDerivedStateFromError(error: any) {
    return { error };
  }
  render() {
    const { error } = this.state;

    if (error) {
      return <>Something went wrong, please try again!</>;
    }
    return (this.props as any).children;
  }
}
export { ErrorBoundary };
