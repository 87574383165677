export function computeParams<Key extends string, Value>(obj: {
  [key in Key]: Value;
}) {
  const keys = Object.keys(obj);
  let param = "";
  for (const key of keys) {
    if (!(obj as any)[key]) {
      continue;
    }
    if (!param) {
      param += `?${key}=${encodeURIComponent(obj[key as Key] as string)}`;
    } else {
      param += `&${key}=${encodeURIComponent(obj[key as Key] as string)}`;
    }
  }

  return param;
}
