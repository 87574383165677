import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CHECK_BIN, SIGNIN_KEY, SIGNUP_KEY } from "@/store/auth/variables";
import { ELang, LoginDto, SignupDto } from "@/models";
import { checkBin, loginApi, signUpApi } from "./api";
import { LANG_STORAGE_KEY, USER_INFO } from "@/variables";

export interface IAuthState {
  loading: boolean;
  isLogged: boolean;
  currentUser: any;
  lang: ELang;
}
const initialState: IAuthState = {
  loading: false,
  isLogged: false,
  lang: (localStorage.getItem(LANG_STORAGE_KEY) as any) || ELang.Russian,
  currentUser: localStorage.getItem(USER_INFO)
    ? JSON.parse(localStorage.getItem(USER_INFO) as string)
    : null,
};

const signInAsync = createAsyncThunk(SIGNIN_KEY, async (body: LoginDto) => {
  return loginApi(body)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((e) => {
      return Promise.reject(e.response.data?.error_code || 5000);
    });
});

const signUpAsync = createAsyncThunk(SIGNUP_KEY, async (body: SignupDto) => {
  return signUpApi(body)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((e) => {
      return Promise.reject(e.response.data?.error_code || 5000);
    });
});

const checkBinAsync = createAsyncThunk(CHECK_BIN, async (value: string) => {
  return checkBin(value)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((er) => {
      return Promise.reject(er.response.data?.error_code || 5000);
    });
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setIsLogged(state, action) {
      state.isLogged = action.payload;
    },
    setLang(state, action) {
      state.lang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(signInAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.isLogged = true;
    });
    builder.addCase(signUpAsync.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(signUpAsync.fulfilled, (state, action) => {
      state.loading = false;
    });
  },
});
export const { setCurrentUser, setIsLogged, setLang } = authSlice.actions;
export { signInAsync, signUpAsync, checkBinAsync };

export default authSlice.reducer;
