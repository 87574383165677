import axios from "axios";

const axiosClient = axios.create();

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
} as any;

//All request will wait 2 seconds before timeout
axiosClient.defaults.timeout = 1000000;

if (!process.env.REACT_APP_DEV) {
  axiosClient.defaults.baseURL = process.env.REACT_APP_URL;
}

axiosClient.defaults.withCredentials = true;

axiosClient.interceptors.response.use(
  (req) => {
    return req;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default axiosClient;
