import { useTranslations } from "use-intl";

export default function Rules() {
  const i18n = useTranslations("rule");
  return (
    <section className="pt-5">
      <div className="container">
        <h3 className="font-weight-bold mb-5">{i18n("title")}</h3>
        <div>
          <div style={{ whiteSpace: "pre-wrap" }}>{i18n("content")}</div>
        </div>
      </div>
    </section>
  );
}
