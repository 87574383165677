import axiosClient from "@/libs/axiosClient";
import { LoginDto, SignupDto } from "@/models";
import { RefreshTokenDto } from "@/models/user/refreshToken.dto";

const enum API_URLS {
  SIGNUP = "/api/v1/users/register/",
  BIN = "/api/v1/users/check_bin/",
  REFRESH_TOKEN = "/api/v1/users/token/refresh/",
  VERIFY = "/api/v1/users/verification_code/verify/",
  VERIFY_SEND = "/api/v1/users/verification_code/send/",
  RESEND_EMAIL = "/api/v1/users/reset_password/send/",
  RESEND_CODE = "/api/v1/users/reset_password/verify/",
  RESEND_PASSWORD = "/api/v1/users/reset_password/",
  LOGIN = "/api/v1/users/token/",
}

export const loginApi = (body: LoginDto) => {
  return axiosClient.post(API_URLS.LOGIN, body).then((res: any) => res);
};

export const signUpApi = (body: SignupDto) => {
  return axiosClient.post(API_URLS.SIGNUP, body);
};

export const refreshToken = (body: RefreshTokenDto) => {
  return axiosClient.post(API_URLS.REFRESH_TOKEN, body).then((res) => res.data);
};

export const checkBin = (val: string) => {
  return axiosClient.get(API_URLS.BIN + `?bin=${val}`);
};

export const verifySend = (email: string) => {
  return axiosClient.post(API_URLS.VERIFY_SEND, { email });
};

export const verifyCode = (code: string, email: string) => {
  return axiosClient.post(API_URLS.VERIFY, { code, email });
};

export const resendPasswordEmail = (email: string) => {
  return axiosClient.post(API_URLS.RESEND_EMAIL, { email });
};

export const resendPasswordCode = (body: { email: string; code: string }) => {
  return axiosClient.post(API_URLS.RESEND_CODE, body).then((res) => res.data);
};

export const resendPassword = (body: {
  email: string;
  reset_token: string;
  password: string;
  password2: string;
}) => {
  return axiosClient.post(API_URLS.RESEND_PASSWORD, body);
};
