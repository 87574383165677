import { CustomDialog } from "@/components/Dialog";
import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { getSubAccountChart$ } from "@/store/sub_accounts";
import { useSubAccount } from "@/hooks";
import { useAppDispatch } from "@/store";
import Chart from "react-apexcharts";
import style from "./index.module.scss";
import { useTranslations } from "use-intl";
import { useChart } from "@/hooks/use-chart";
const TIMES = ["10MIN", "HOUR", "DAY"];

export function StatisticDetails({
  onClose,
  show,
}: {
  onClose: () => void;
  show: boolean;
}) {
  const { chart, setChartByInterval } = useChart();
  const dispatch = useAppDispatch();
  const { currentSubAccount } = useSubAccount();
  const [loading, setLoading] = useState(true);
  const i18n = useTranslations("cabinet.statistics");
  const [currentTime, setCurrentTime] = useState("10MIN");
  const promise = useRef<any>();
  useEffect(() => {
    if (!currentSubAccount) {
      return;
    }
    promise.current = getGraphic(currentTime);
    return () => {
      if (promise.current) {
        promise.current.abort();
      }
    };
  }, [currentTime]);

  const getGraphic = (interval: string) => {
    if (promise.current) {
      promise.current.abort();
    }
    setLoading(true);
    const dispatchReq = dispatch(
      getSubAccountChart$({
        sub_account_name: currentSubAccount,
        interval: interval,
      }),
    );
    dispatchReq
      .unwrap()
      .then((res: any) => {
        setLoading(false);
        setChartByInterval(interval as any, res);
      })
      .catch(() => {
        setLoading(false);
      });
    return dispatchReq;
  };

  return (
    <CustomDialog
      onClose={() => {
        onClose();
      }}
      heading={<p></p>}
      size={"xl"}
      body={
        <div>
          <div className={"d-flex align-items-center gap-3 mb-3"}>
            <h3 className={style.graphic__title}>{i18n("graphic")}</h3>
            <DropdownButton
              as={ButtonGroup}
              key={"btn"}
              id={`dropdown-variants-Secondary`}
              variant={"secondary"}
              title={currentTime}
            >
              {TIMES.map((item, idx) => (
                <Dropdown.Item
                  eventKey={idx}
                  key={idx}
                  active={currentTime === item}
                  onClick={() => {
                    setCurrentTime(item);
                  }}
                >
                  {item}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>

          <div>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <div id="chart">
                  <div id="chart-timeline">
                    <Chart
                      options={chart.options as any}
                      series={chart.series}
                      type="area"
                      height={350}
                    />
                  </div>
                </div>
                <div id="html-dist"></div>
              </>
            )}
          </div>
        </div>
      }
      show={show}
    />
  );
}
