import axiosClient from '@/libs/axiosClient';

export const enum REFERENCE_URL {
    EARNING_SCHEME = '/api/v1/pools/sub_account/references/earning_schemes/',
    CURRENCY = '/api/v1/pools/sub_account/references/crypto_currency/'
}

export const getEarningScheme = () => {
    return axiosClient.get(REFERENCE_URL.EARNING_SCHEME);
}

export const getCurrencies = () => {
    return axiosClient.get(REFERENCE_URL.CURRENCY);
}
