import style from "./index.module.scss";
import { ButtonGroup, Dropdown, DropdownButton, Row } from "react-bootstrap";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslations } from "use-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { setLang } from "@/store/auth";
import { LANG_STORAGE_KEY } from "@/variables";
const LANGS = [
  {
    label: "English",
    key: "en",
  },
  {
    label: "Русский",
    key: "ru",
  },
  {
    label: "Қазақша",
    key: "kz",
  },
];
export function Footer() {
  const i18n = useTranslations("footer");
  const lang = useSelector((state: RootState) => state.auth.lang);
  const dispatch = useDispatch();
  return (
    <div className={style.footer}>
      <div className={"container"}>
        <Row className={style.footer__top}>
          <div className="col-lg-4 col-md-6">
            <div className="footer-item mb-5">
              <a href="/" className="d-block">
                <img
                  src={require("@/images/main/main-logo-sticky.png")}
                  alt="logo"
                />
              </a>
              <ul className="list-items pt-4">
                <li className="mb-3">
                  <a href="mailto:info@btcpool.kz">
                    <i className="fal fa-envelope mr-1 font-size-14"></i>{" "}
                    info@btcpool.kz
                  </a>
                </li>
                <li className="mb-3">
                  <a href="tel:+77775210024">
                    <i className="fal fa-phone mr-1 font-size-14"></i>{" "}
                    +7(777)-521-00-24
                  </a>
                </li>
                <li className="mb-3">
                  <i className="fal fa-map-marker-alt mr-1 font-size-14"></i>{" "}
                  Астана, Казахстан
                </li>
              </ul>
              <div className={style.footer__top__socialIcons}>
                <a
                  href="https://wa.me/message/RCRYW463DQV2D1"
                  target="_blank"
                  className="icon-element icon-element-sm ml-2"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>
                <a
                  href="https://twitter.com/BTCPoolkz"
                  target="_blank"
                  className="icon-element icon-element-sm"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://t.me/Btcpoolkz_admin"
                  target="_blank"
                  className="icon-element icon-element-sm mr-1"
                >
                  <i className="fab fa-telegram-plane"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="footer-item mb-5">
              <h5 className="mb-3 font-weight-semi-bold">BTCpool.kz</h5>
              <div className={classNames("border-bottom-0", style.titleShape)}>
                <span></span>
              </div>
              <div className="title-shape border-bottom-0">
                <span></span>
              </div>
              <ul className="list-items list-items-hover pt-4">
                <li className="mb-2">
                  <Link to="/about-us">{i18n("aboutUs")}</Link>
                </li>
                <li className="mb-2">
                  <Link to="/policy">{i18n("policy")}</Link>
                </li>
                <li className="mb-2">
                  <Link to="/tariff">{i18n("tariff")}</Link>
                </li>
                <li className="mb-2">
                  <Link to="/licenses">{i18n("licenses")}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="footer-item mb-5">
              <h5 className="mb-3 font-weight-semi-bold">Support</h5>
              <div className={classNames("border-bottom-0", style.titleShape)}>
                <span></span>
              </div>
              <div className="title-shape border-bottom-0">
                <span></span>
              </div>
              <ul className="list-items list-items-hover pt-4">
                <li className="mb-2">
                  <Link to="/rules">{i18n("rules")}</Link>
                </li>
                <li className="mb-2">
                  <Link to="/support">{i18n("support")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </Row>
        <hr className={"border-top-gray my-4"} />
        <div className={style.footer__bottom}>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <p className="copy-desc p-0 m-0">
              Copyright © 2024 BTCpool.kz. All Rights Reserved.
            </p>
            <div className="select-picker-wrap d-flex gap-3">
              <DropdownButton
                as={ButtonGroup}
                key={"btn"}
                className={style.footer__bottom__padding}
                id={`dropdown-variants-Secondary`}
                variant={"secondary"}
                title={(LANGS.find((item) => item.key === lang) as any).label}
              >
                {LANGS.map((item, idx) => (
                  <Dropdown.Item
                    eventKey={idx}
                    key={idx}
                    active={lang === item.key}
                    onClick={() => {
                      dispatch(setLang(item.key));
                      localStorage.setItem(LANG_STORAGE_KEY, item.key);
                    }}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {/*<DropdownButton*/}
              {/*  as={ButtonGroup}*/}
              {/*  key={"Secondary"}*/}
              {/*  id={`dropdown-variants-Secondary`}*/}
              {/*  variant={"secondary"}*/}
              {/*  title={"RUB"}*/}
              {/*>*/}
              {/*  <Dropdown.Item eventKey="1">USD</Dropdown.Item>*/}
              {/*  <Dropdown.Item eventKey="2">KZT</Dropdown.Item>*/}
              {/*  <Dropdown.Item eventKey="3" active>*/}
              {/*    RUB*/}
              {/*  </Dropdown.Item>*/}
              {/*</DropdownButton>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
