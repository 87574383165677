import style from "./index.module.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "@/styles/cabinet.scss";
import { Alert } from "@/components/Alert";
import { getSubAccountStratum$, setShowModal } from "@/store/sub_accounts";
import { RootState, useAppDispatch } from "@/store";
import { useSubAccount } from "@/hooks";
import { useSelector } from "react-redux";
import { getCurrencies$ } from "@/store/references";
import { AddSubAccountDialog } from "@/features";
import { useTranslations } from "use-intl";
import { useJwt } from "react-jwt";
import { USER_INFO, USER_TOKEN } from "@/variables";
import { verifySend } from "@/store/auth/api";

const BTNS = [
  {
    name: "statistics",
    label: "btn.statistics",
    url: "/cabinet/statistics",
  },
  {
    name: "earnings",
    label: "btn.earnings",
    url: "/cabinet/earnings",
  },
  {
    name: "payout",
    label: "btn.payouts",
    url: "/cabinet/payout",
  },
  {
    name: "observers",
    label: "btn.observers",
    url: "/cabinet/observers",
  },
  {
    name: "settings",
    label: "btn.settings",
    url: "/cabinet/settings",
  },
  {
    name: "subaccounts",
    label: "btn.subaccount",
    url: "/cabinet/subaccounts",
  },
];

export function CabinetLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [alert, setAlert] = useState(false);
  const dispatch = useAppDispatch();
  const { currentSubAccount } = useSubAccount();
  const stratumUrls = useSelector(
    (state: RootState) => state.subAccount.stratumUrls,
  );
  const showModal = useSelector(
    (state: RootState) => state.subAccount.showModal,
  );
  const currencies = useSelector(
    (state: RootState) => state.references.currencies,
  );
  const i18n = useTranslations("cabinet");
  const checkVerification = () => {
    const token = JSON.parse(
      localStorage.getItem(USER_INFO) || JSON.stringify({}),
    );
    if (token) {
      if (!token.user?.is_email_verified) {
        verifySend(token.user?.email).then();
        navigate("/verify");
      }
    }
  };
  useEffect(() => {
    if (currentSubAccount) {
      dispatch(getSubAccountStratum$(currentSubAccount)).unwrap();
    }
    if (!currencies?.length) {
      dispatch(getCurrencies$());
    }

    checkVerification();
  }, [currentSubAccount]);
  return (
    <section className={style.cabinetLayout}>
      {showModal && (
        <AddSubAccountDialog onClose={() => dispatch(setShowModal(false))} />
      )}
      <div className={"container pt-5"}>
        <div className={style.cabinetLayout__heading}>
          <h3>{i18n("title")}</h3>
        </div>
        {alert && (
          <Alert
            text={"Скопировано"}
            onClose={() => {
              setAlert(false);
            }}
          />
        )}
        <div className={style.cabinetLayout__navigation}>
          {BTNS.map((btn, idx) => (
            <button
              className={classNames(
                "btn",
                location.pathname.includes(btn.name) && style.active,
              )}
              onClick={() => navigate(btn.url)}
              key={idx}
            >
              {i18n(btn.label)}
            </button>
          ))}
        </div>
        <div className={style.cabinetLayout__content}>
          <Outlet />
        </div>
      </div>
      <div className={style.cabinetLayout__mining}>
        <div className={"container"}>
          <div className={"row position-relative"}>
            <div className={"col-lg-7"}>
              <div className={style.cabinetLayout__mining__heading}>
                <h2>{i18n("mining.title")}</h2>
                <DropdownButton
                  as={ButtonGroup}
                  key={"light"}
                  id={`dropdown-variants-light`}
                  variant={"light"}
                  title={"BTC"}
                >
                  {currencies.map((currency, idx) => (
                    <Dropdown.Item eventKey={idx} key={idx}>
                      {currency}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <p className="mb-4">{i18n("mining.subtitle")}</p>
              <div className={style.cabinetLayout__mining__urls}>
                {stratumUrls.map((item, idx) => {
                  return (
                    <div
                      className={style.cabinetLayout__mining__urls__item}
                      key={idx}
                    >
                      <div>
                        <span>{item.description}</span>
                      </div>
                      <div
                        className={
                          style.cabinetLayout__mining__urls__item__input
                        }
                      >
                        <input
                          type="text"
                          value={item.url}
                          readOnly
                          className="form-control form--control bg-white border border-gray copyButton"
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary copyButton"
                            data-copytarget="url_1"
                            onClick={() => setAlert(true)}
                          >
                            <i className="fal fa-copy ml-1"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={"col-lg-5"}>
              <div className="newsletter-shape position-absolute bottom-0 right-0">
                <img
                  src={require("@/images/main/bitcoin.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
