export function formatHash(val: number) {
  if (val > 1_000_000_000_000) {
    return (val / 1_000_000_000_000).toFixed(3) + " EH/s";
  } else if (val > 1_000_000_000) {
    return (val / 1_000_000_000).toFixed(3) + " PH/s";
  } else {
    return (val / 1_000_000).toFixed(3) + " TH/s";
  }
}
export function toDifficulty(val: number) {
  let value = "";
  let strArg = Math.round(val).toString().split("").reverse();
  for (let x = strArg.length - 1; x >= 0; x--) {
    if ((x + 1) % 3 === 0 && x > 0 && x !== strArg.length - 1) {
      value += ",";
    }
    value += strArg[x];
  }
  return value;
}
export function expo(x: number, f: number) {
  return Number.parseFloat(x as any).toExponential(f);
}
