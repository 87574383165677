import { customRequest } from "@/utils/custom-fetch";
import { IObserverCreate } from "@/models";

enum API_URL {
  all = "/api/v1/pools/sub_account/observer_link/all/",
  create = "/api/v1/pools/sub_account/observer_link/create/",
}
export const getAllObservers = () => {
  return customRequest.getItem(API_URL.all);
};
export const addObserver = (body: IObserverCreate) => {
  return customRequest.post(API_URL.create, body).then((res: any) => res.data);
};

export * from "./watcher-link";
