import { useTranslations } from "use-intl";

export default function Licenses() {
  const i18n = useTranslations("licenses");
  return (
    <section className="pt-5">
      <div className="container">
        <h3 className="font-weight-bold">{i18n("title")} </h3>
        <div className="mb-4 mt-4">{i18n("content")}</div>

        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body font-size-14">
                <div className="card-title mb-4">
                  <div uk-lightbox="">
                    <a
                      href="/images/license1.png"
                      className="link-dark"
                      role="button"
                    >
                      {i18n("card1")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body font-size-14">
                <div className="card-title mb-4">
                  <div uk-lightbox="">
                    <a
                      href="/images/license2.jpg"
                      className="link-dark"
                      role="button"
                    >
                      {i18n("card2")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
