import { useTranslations } from "use-intl";

export default function SupportPage() {
  const i18n = useTranslations("support");
  return (
    <section className="pt-5">
      <div className="container">
        <h3 className="font-weight-bold mb-5">{i18n("title")}</h3>
        <div>
          <h2 className="sec-title mb-3"> {i18n("title2")}</h2>
          <div>{i18n("content")}</div>
          <h5 className="mb-3 mt-3"> {i18n("title3")}</h5>
          <div dangerouslySetInnerHTML={{ __html: i18n("question1") }}></div>
          <img
            src={require("@/images/support/screen1.png")}
            className="img-fluid"
          />
          <div
            className="mb-3 mt-3"
            dangerouslySetInnerHTML={{ __html: i18n("question2") }}
          ></div>
          <img src={require("@/images/support/screen2.png")} />
          <div
            className="mb-3 mt-3"
            dangerouslySetInnerHTML={{ __html: i18n("hint") }}
          ></div>
        </div>
      </div>
    </section>
  );
}
