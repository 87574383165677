import { Card, Spinner, Table, Alert as BootAlert } from "react-bootstrap";
import style from "./index.module.scss";
import { RootState, useAppDispatch } from "@/store";
import { useSelector } from "react-redux";
import { useSubAccount } from "@/hooks";
import { useEffect, useState } from "react";
import {
  checkCode,
  createApiKey$,
  deleteApiKey$,
  generateQrCode,
  getAllApiKeys$,
  isQrCodeEnabled,
  qrCodeEnable,
} from "@/store/api_key";
import { Input } from "@/components/Input";
import { Alert } from "@/components/Alert";
import { useTranslations } from "use-intl";

export default function Settings() {
  const dispatch = useAppDispatch();
  const apiKeys = useSelector((state: RootState) => state.apiKey.apiKeys);
  const { currentSubAccount } = useSubAccount();
  const loading = useSelector((state: RootState) => state.apiKey.loading);
  const [faCode, setFaCode] = useState("");
  const [alert, setAlert] = useState<any>(null);
  const [qr, setQr] = useState<string>("");
  const [loadingQr, setLoading] = useState(true);
  useEffect(() => {
    let promise: any;
    if (currentSubAccount) {
      promise = dispatch(getAllApiKeys$(currentSubAccount));
    }
    isQrCodeEnabled().then(({ is_otp_enabled }: any) => {
      if (!is_otp_enabled) {
        generateQrCode()
          .then((res: any) => {
            setQr(res);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [currentSubAccount]);
  const createApiKey = () => {
    dispatch(createApiKey$(currentSubAccount));
  };
  const deleteKey = (key: string) => {
    dispatch(
      deleteApiKey$({ sub_account_name: currentSubAccount, api_key: key }),
    );
  };
  const i18n = useTranslations("cabinet.settings");

  const addFaKey = () => {
    qrCodeEnable(faCode)
      .then(() => {
        setAlert({ text: i18n("success2fa"), type: "success" });
      })
      .catch(() => {
        setAlert({
          text: i18n("invalid2fa"),
          type: "error",
        });
      });
  };
  return (
    <div>
      {alert && (
        <Alert
          text={alert.text}
          type={alert.type}
          onClose={() => {
            setAlert(null);
          }}
        />
      )}
      <Card className={style.settings}>
        <h5>{i18n("useApi")}.</h5>
        {loading ? (
          <Spinner animation={"grow"} />
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Api Key </th>
                  <th>{i18n("table.created")}</th>
                </tr>
              </thead>
              <tbody>
                {apiKeys.map((key, idx) => (
                  <tr key={idx}>
                    <td>
                      <i
                        className={"fa fa-trash-alt"}
                        onClick={() => deleteKey(key)}
                      ></i>
                      {key}
                    </td>
                    <td>-</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <button
              className={"btn btn-primary btn-sm"}
              onClick={() => createApiKey()}
            >
              {i18n("showApi")}
            </button>
          </>
        )}
      </Card>
      <Card className={style.settings}>
        {loadingQr ? (
          <Spinner animation={"grow"} />
        ) : (
          <>
            <h5>{i18n("2fa")}</h5>
            {qr ? (
              <div className={style.settings__qr}>
                <h5>{i18n("heading")}</h5>
                <div className={style.settings__qr__content}>
                  <div dangerouslySetInnerHTML={{ __html: qr }}></div>
                  <div>
                    <p>{i18n("description")}</p>
                    <div className={"d-flex gap-3"}>
                      <Input
                        placeholder={"2FA CODE"}
                        value={faCode}
                        onChange={(e) => setFaCode(e.target.value)}
                      />
                      <div>
                        <button
                          className={"btn btn-primary "}
                          onClick={() => addFaKey()}
                        >
                          {i18n("bind")}
                        </button>
                      </div>
                    </div>
                    {alert && (
                      <BootAlert
                        variant={alert.type === "error" ? "danger" : alert.type}
                        className={style.settings__qr__alert}
                      >
                        {alert.text}
                      </BootAlert>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <BootAlert variant={"success"}>{i18n("enabled")}</BootAlert>
            )}
          </>
        )}
      </Card>
    </div>
  );
}
