import { useEffect, useState } from "react";

export function useDebounce(value: string, timer: number) {
  const [val, setValue] = useState("");
  let timeout: any;
  useEffect(() => {
    timeout = setTimeout(() => {
      setValue(value);
    }, timer);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [value]);
  return val;
}
