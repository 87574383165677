import { RootState, useAppDispatch } from "@/store";
import { useSelector } from "react-redux";
import { useSubAccount } from "@/hooks";
import React, { useEffect, useState } from "react";
import {
  getAllSubAccounts$,
  setLoading,
  setShowModal,
} from "@/store/sub_accounts";
import { Spinner } from "react-bootstrap";
import { Card } from "@/components/Card";
import { CustomTable } from "@/components/Table/CustomTable";
import { useTranslations } from "use-intl";
import style from "./index.module.scss";
import classNames from "classnames";
import { Input } from "@/components/Input";
import { useDebounce } from "@/hooks/use-debounce";

export default function SubAccountsPage() {
  const dispatch = useAppDispatch();
  const i18n = useTranslations("cabinet");
  const headerTranslation = useTranslations("header");
  const [search, setSearch] = useState("");
  const [cachedSubAccounts, setCachedSubAccounts] = useState<any[]>([]);
  const subAccounts = useSelector(
    (state: RootState) => state.subAccount.subAccounts,
  );
  const [sorts, setSorts] = useState<Partial<{ [key in string]: boolean }>>({});
  const debouncedValue = useDebounce(search, 400);
  const { loading } = useSubAccount();
  useEffect(() => {
    let promise: any;
    dispatch(setLoading(true));
    promise = dispatch(getAllSubAccounts$());
    promise.finally(() => dispatch(setLoading(false)));
    return () => {
      dispatch(setLoading(false));
      if (promise) {
        promise.abort();
      }
    };
  }, []);
  useEffect(() => {
    setCachedSubAccounts(subAccounts);
  }, [subAccounts]);
  useEffect(() => {
    setCachedSubAccounts(
      subAccounts.filter((subAccount) =>
        subAccount.name.includes(debouncedValue),
      ),
    );
  }, [debouncedValue]);
  const handleSortByName = (isDesc: boolean) => {
    const returnValue = isDesc ? 1 : -1;
    setCachedSubAccounts((prev) =>
      [...prev].sort((a, b) => {
        return a.name > b.name ? returnValue * -1 : returnValue;
      }),
    );
  };
  return (
    <>
      {loading ? (
        <Spinner animation={"grow"} />
      ) : (
        <>
          <Input
            style={{ width: "auto" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={i18n("subaccounts.search")}
          />
          <Card>
            <button
              className={classNames(
                "btn btn-sm btn-light text-black m-4",
                style.block,
              )}
              onClick={() => dispatch(setShowModal(true))}
            >
              {headerTranslation("addSubAccount")}
            </button>
            <CustomTable
              notFoundText={i18n("notFoundText")}
              header={[
                "ID",
                <div className={"d-flex gap-2"}>
                  {i18n("subaccounts.header.username")}
                  <img
                    src={require("@/images/main/icons/arrow-down.svg").default}
                    alt={"arrowDown"}
                    className={
                      sorts["name"] ? style.rotate : "cursor-pointer d-block"
                    }
                    onClick={() => {
                      const currentState = sorts["name"];
                      setSorts((prev) => ({
                        ...prev,
                        name: !currentState,
                      }));
                      handleSortByName(!currentState);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>,
                i18n("subaccounts.header.commission"),
                i18n("subaccounts.header.currency"),
                i18n("subaccounts.header.wallet"),
                i18n("subaccounts.header.earningScheme"),
              ]}
              body={cachedSubAccounts.map((account) => Object.values(account))}
            />
          </Card>
        </>
      )}
    </>
  );
}
