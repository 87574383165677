import { IntlProvider } from "use-intl";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { messages } from "@/libs";

export function LangLayout({ children }: any) {
  const lang = useSelector((state: RootState) => state.auth.lang);

  return (
    <IntlProvider messages={messages[lang]} locale={lang}>
      {children}
    </IntlProvider>
  );
}
