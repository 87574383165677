import { Header } from "@/components";
import { Footer } from "@/components/Footer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { setCurrentUser, setIsLogged } from "@/store/auth";
import { IntlProvider } from "use-intl";
import { messages } from "@/libs";
import { ErrorBoundary } from "@/components/ErrorBoundary";

export function MainLayout({ children }: { children: any }) {
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const lang = useSelector((state: RootState) => state.auth.lang);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser) {
      const givenDate = new Date((currentUser as any).created_date);
      givenDate.setDate(givenDate.getDate() + 60);
      if (new Date() < givenDate) {
        dispatch(setIsLogged(true));
      } else {
        dispatch(setIsLogged(false));
        dispatch(setCurrentUser(null));
      }
    }
  }, [currentUser]);
  return (
    <>
      <IntlProvider messages={messages[lang]} locale={lang}>
        <Header />
        <ErrorBoundary>{children}</ErrorBoundary>
        <Footer />
      </IntlProvider>
    </>
  );
}
