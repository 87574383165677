import style from "./index.module.scss";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "@/store";
import { useSubAccount } from "@/hooks";
import { getSubAccountSummary$, setLoading } from "@/store/sub_accounts";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useTranslations } from "use-intl";
import { formatHash } from "@/utils/to-hash";
import { StatisticDetails } from "@/features";

export default function Statistic() {
  const dispatch = useAppDispatch();
  const { currentSubAccount, loading } = useSubAccount();
  const summary = useSelector(
    (state: RootState) => state.subAccount.subAccountSummary,
  );
  const [openDetail, setOpenDetail] = useState(false);
  const i18n = useTranslations("cabinet.statistics");
  useEffect(() => {
    let promise: any;
    if (currentSubAccount) {
      dispatch(setLoading(true));
      promise = dispatch(getSubAccountSummary$(currentSubAccount));
    }
    return () => {
      dispatch(setLoading(false));
      if (promise) {
        promise.abort();
      }
    };
  }, [currentSubAccount]);
  return (
    <div className={style.coins}>
      {!loading ? (
        summary?.map((item, idx) => (
          <div
            className={classNames(style.coins__item, "col-lg-3 col-md-6")}
            key={idx}
          >
            <div className={style.coins__item__heading}>
              <h4 className="card-title mb-2">
                <img
                  src={require(
                    `@/images/main/coins/${item.crypto_currency}.png`,
                  )}
                  alt="BTC."
                />{" "}
                BTC
              </h4>
            </div>
            <div
              className={classNames(
                style.coins__item__card,
                style[item.crypto_currency],
              )}
            >
              <ul>
                <li>
                  <p>{i18n("hashrate1h")}</p>
                  <span>{formatHash(item.hashrate_1hour)}</span>
                </li>
                <li>
                  <p>{i18n("hashrate10min")}</p>
                  <span>{formatHash(item.hashrate_10min)}</span>
                </li>
                <li>
                  <p>{i18n("hashrate24h")}</p>
                  <span>{formatHash(item.hashrate_24hour)}</span>
                </li>
                <li>
                  <p>{i18n("worker")}</p>
                  <span>{item.active_workers}</span>
                </li>
                <li>
                  <p>{i18n("balance")}</p>
                  <span>{item.balance} BTC</span>
                </li>
                <li>
                  <p>{i18n("inactive_workers")}</p>
                  <span>{item.unactive_workers}</span>
                </li>
                <li>
                  <button
                    className={"btn btn-sm"}
                    onClick={() => {
                      setOpenDetail(!openDetail);
                    }}
                  >
                    {i18n("detail")}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ))
      ) : (
        <Spinner animation="grow" />
      )}
      {openDetail && (
        <StatisticDetails
          onClose={() => setOpenDetail(false)}
          show={openDetail}
        />
      )}
    </div>
  );
}
